import React from "react";
import Layout from "../components/layout";
import { Container, Row, Col } from "react-bootstrap";
import Seo from "../components/seo";
//Images
import { StaticImage } from "gatsby-plugin-image";

const PropertyManagers = () => {
    return (
        <Layout>
            <Seo
                title="Immobilienmanager - BIKESAFE"
                description="Platz, Wert und Sicherheit sind wichtige Faktoren für Immobilienmanager. Bike Safe bietet Fahrradaufbewahrungssysteme für alle Installationsarten an, die die Anforderungen von Immobilienmanagern erfüllen. Ganz gleich, ob Sie einem gesamten Raum oder eine nur eine bestimmte Fläche für Fahrradstellplätze vorsehen möchten – wir haben die Lösung."
            />
            <Container>
                <Row>
                    <Col sm="12">
                        <h1 className="mt-5 mb-5 text-center">
                        Immobilienmanager</h1>
                    </Col>
                </Row>
                <Row>
                    <Col sm="12" className="text-center">
                        <p className="flow-text">
                        Platz, Wert und Sicherheit sind wichtige Faktoren für Immobilienmanager. Bike Safe bietet Fahrradaufbewahrungssysteme für alle Installationsarten an, die die Anforderungen von Immobilienmanagern erfüllen. Ganz gleich, ob Sie einem gesamten Raum oder eine nur eine bestimmte Fläche für Fahrradstellplätze vorsehen möchten – wir haben die Lösung.
                        </p>
                        <p className="flow-text">
                        Wir können Sie mit einem CAD-Grundriss des Fahrradabstellplatzes bei der Optimierung der Fahrradaufbewahrung unterstützen.
                        </p>
                    </Col>
                </Row>
                <Row>
                    <Col sm="12" className="text-center">
                        <StaticImage placeholder="blurred"  src="../images/bristol-shelter.jpg" className="shadow mt-5 mb-5 fluid rounded" />
                        <p>
                            <em className="flow-text">"Vielen Dank für die exzellente Arbeit, die Sie mit den Fahrradständern geleistet haben. Sie sehen einfach toll aus und werden schon fleißig genutzt!"
                                <strong> John Hilton, SMBC Group (Immobilienmanager), London</strong>.</em></p>
                    </Col>
                </Row>
            </Container>
        </Layout>
    )
}

export default PropertyManagers;